.cohort-grades .grade-0 {
    background: #f4c7c4;
    color: #4f4f4f;
}
.cohort-grades .grade-1 {
    background: #f4c7c4;
    color: #4f4f4f;
}
.cohort-grades .grade-3 {
    background: #b8e1cc;
    color: #4f4f4f;
}
.cohort-grades .grade-5 {
    background: #124f5c;
    color: white;
}

.cohort-grades .grade-uncompleted {
    background: #e5e5e5;
    color:#4f4f4f;
}
.cohort-grades .grade-not-graded {
    background: #e5e5e5;
    color: #4f4f4f;
}
.cohort-grades .grade-draft.grade-null {
    background: #e5e5e5;
    color: #4f4f4f;
}

.cohort-grades .grade-missing {
    background: #9f605b;
    color: #f5e9e9;
}

.cohort-grades .grade-resubmission-due {
    border: 4px solid #9f605b;
}

.cohort-grades .grade-resubmission-complete {
    border: 4px solid #9f605b;
    background: #b8dde1;
}

.cohort-grades .grade-resubmission-accepted {
    background: #b8dde1;
}
.cohort-grades .grade-late.grade-1 {
    background-image: linear-gradient(135deg, #ebbdb9 25%, #deafab 25%, #deafab 50%, #ebbdb9 50%, #ebbdb9 75%, #deafab 75%, #deafab 100%);
}
.cohort-grades .grade-late.grade-3 {
    background-image: linear-gradient(135deg, #b8e1cc 25%, #b6cfc2 25%, #b6cfc2 50%, #b8e1cc 50%, #b8e1cc 75%, #b6cfc2 75%, #b6cfc2 100%);
}
.cohort-grades .grade-late.grade-5 {
    background-image: linear-gradient(135deg, #124f5c 25%, #395b63 25%, #395b63 50%, #124f5c 50%, #124f5c 75%, #395b63 75%, #395b63 100%);
}

/* No coursework for this cell (eg. transferred after assignment assigned) */
.cohort-grades .grade-none {
    background: #e5e5e5;
}

.cohort-grades .grade-avg {
    background-color: #517265;
    color: white;
    float: right;
    margin: -5px -60px -5px -5px;
    padding: 5px 10px;
}
.cohort-grades .grade-avg-neutral {
    background-color: #e5e5e5;
    color: #676a6c;
}
.cohort-grades .grade-avg-warning {
    background-color: #867d1d;
    color: white;
}
.cohort-grades .grade-avg-critical {
    background-color: #882923;
    color: white;
}