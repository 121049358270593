* {
    margin: 0;
    /* padding: 0; */
    margin-block-start: 0;
    margin-block-end: 0;
}

table {
    border-collapse: collapse;
    width: 100%;
}

/* 
    START styling for markdown. TODO: Wrap this in an element with
    a class to avoid chaning global elements.
*/
hr {
    margin-top: 15px;
    margin-bottom: 15px;
}

th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

code {
    background-color: #F9F2F4;
    border-radius: 4px;
    color: #ca4440;
    font-size: 90%;
    padding: 2px 4px;
    white-space: nowrap;
}

.mde-text {
    font-size: 1em;
}

.applicantNote p{
    max-width: 450px;
    }

    .applicantNote img {
        width: 100%;
        object-fit: contain;
    }

/* END markdown styling */

body {
    font-family: 'Open Sans', sans-serif;
    background-color: #F3F3F4;
    font-size: 16px;
}


h1 {
    font-size: 30px;
    font-weight: 400;
    color: #676a6c;
}

h2 {
    font-size: 26px;
    font-weight: 400;
    color: #676a6c;
}

h3 {
    font-size: 24px;
    font-weight: 400;
    color: #676a6c;
}

h4 {
    font-size: 24px;
    font-weight: 700;
    color: #676a6c; 
}

h5 {
    font-size: 18px;
    font-weight: 700;
    color: #676a6c;
}

h6 {
    font-size: 16px;
    font-weight: 700;
    color: #676a6c;
}

p {
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 0px;
    color: #1b1b1b;
}

ul {
    padding-top: 10px;
    padding-bottom: 10px;
}

li {
    color: #1b1b1b;
}

a:link, a:visited {
    color: #337277;
    text-decoration: none;
}

a:hover {
    color: #1c3f41;
}

.clickable-icon {
    opacity: 0.8;
    cursor: pointer;
}

.clickable-icon:hover {
    opacity: 0.9;
    cursor: pointer;
}

.clickable-icon:active {
    opacity: 1;
    cursor: pointer;
}

.fade {
    position: relative; 
    margin-top: -40px;
    height: 40px;
    background: -webkit-linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    ); 
    background-image: -moz-linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    background-image: -o-linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    background-image: linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    background-image: -ms-linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
} 

.page-title {
    width: 100%;
    background-color: white;
}

.container {
    background-color: #F3F3F4;
    min-height: 100vh;
    padding-bottom: 5px;
    width: 100%;
}

.placeholder-photo-xs {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #ccc;
    align-self: center;
    flex: 0 0 auto;
}

.placeholder-photo-sm {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ccc;
}

.placeholder-photo-lg {
    float: left;
    height: 200px;
    width: 200px;
    margin: 15px;
    background-color: lightgray;
}

.placeholder-photo-preview {
    float: left;
    border: 1px solid #333;
    height: 200px;
    width: 200px;
    background-color: lightgray;
    object-fit: cover;
}

.status-text {
    padding: 5px 10px 5px 10px;
    color: white;
    font-size: 12px;
    border-radius: 3px;
    white-space: nowrap;
}

.status-early {
    background-color: #07aa9e;
}

.status-on-time {
    background-color: #08d7c8;
}

.status-late {
    background-color: #FC754E;
}

.status-uncompleted {
    background-color: gray;
}

.status-missing {
    background-color: #AB3E07;
}

.status-graded {
    background-color: #1c84c6;
}

.status-placed {
    background-color: #07aa9e;
}

.status-excluded {
    background-color: #f8ac59;
}

.status-unplaced {
    background-color: #ccc;
    color: #444;
}

.status-graduated {
    background-color: #07aa9e;
}

.status-classroom {
    background-color: #1c84c6;
}

.status-application-closed {
    background-color: #23c6c8;
}

.status-complete {
    background-color: #07aa9e;
}

.status-incomplete {
    background-color: #FCCE4E;
}

.text-center {
    text-align: center;
}

.table-padding {
    margin: 15px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: white;
}

.display-linebreak {
    white-space: pre-line;
}

/* 
    ChartJS has an issue with responsive charts. Using !important as a
    last resort to fix this issue.
    https://github.com/jtblin/angular-chart.js/issues/614
*/
.responsive-chart canvas {
    width: 100% !important;
    height: 240px;
}

/* Remove bullets from Markdown checklists */
.contains-task-list {
    list-style: none;
    padding: 16px 0 16px 16px;
}

.mde-preview-content {
    padding: 15px;
    max-width: 900px;
    word-wrap: break-word;


}

.mde-preview-content img{
    width: 100%;
}

.app-root {
    display: flex;
}

.app-content {
    flex-grow: 1;
    margin-bottom: '50vh';
}